<template>
  <ZButton
    data-testid="ask-owner-a-question"
    outline
    size="md"
    block
    @click="showModal"
  >
    {{ t("cta") }}
  </ZButton>

  <LazyBookingAskOwnerQuestionModal
    v-if="showQuestionModal"
    @close="closeModal"
  />
</template>

<script setup lang="ts">
import { messageAppToSignup, ACTIONS } from '~/lib/mobileMessages/messageAppToSignup'

const {
  rvId,
  rvOwnerFirstName,
  rvOwnerLastName,
} = useRvDetails()

const ownerName = computed(() => rvOwnerFirstName.value && rvOwnerLastName.value ? formatNameAsFullName(rvOwnerFirstName.value, rvOwnerLastName.value.charAt(0)) : 'Unknown') // TODO: formatter?

const { t } = useI18n()

const askQuestionAfterSignup = ref(false)
const { isLoggedIn } = useAuthentication()
const { isWebView } = usePlatform()
const showQuestionModal = useShowOwnerQuestionModal()

// Test
const { showSignup, authenticationIntent, enquiryOwnerName } = useSignup()
// Test

function showModal() {
  showQuestionModal.value = true
  if (isLoggedIn.value) {
    // TODO: implement modal
    // $nuxt.$emit('open-ask-owner-question-modal', { name })
    showQuestionModal.value = true
    return
  }

  if (isWebView.value) {
    const askQuestionUrl = `${window.location.href}?ask-question=1`
    const cookiesJSON = {
      [rvId.value]: useCookie(rvId.value).value,
    }

    messageAppToSignup({
      successUrl: askQuestionUrl,
      failureUrl: window.location.href,
      cookies: cookiesJSON,
      ownerName: ownerName.value,
      action: ACTIONS.ENQUIRY,
    })

    return
  }

  askQuestionAfterSignup.value = true

  // TODO: implement signup intent
  // this.$nuxt.$emit('signup', { isEnquiryIntent: true, ownerName: this.name })
  console.log('show signup')
  showSignup({ intent: authenticationIntent.value, ownerName: enquiryOwnerName.value })
}

function closeModal() {
  showQuestionModal.value = false
}
</script>

<i18n lang="json">
{
  "en": {
    "cta": "Ask a question"
  },
  "fr": {
    "cta": "Poser une question"
  }
}
</i18n>
