<template>
  <section class="rules">
    <h3 class="mb-3">
      {{ t('rules') }}
    </h3>

    <h5 class="checkin-checkout">
      <span>{{ checkInTime }}</span>
      •
      <span>{{ checkOutTime }}</span>
    </h5>

    <ZGrid>
      <ZGridItem
        v-if="rvAllowOnlyExperiencedSimilarRV"
        lg="6"
        class="mb-3"
      >
        <div class="rule">
          <template v-if="rvIsMotorized">
            <fa :icon="['fal', 'car']" />
            {{ $t('rv.preferences.experiencedDriverRequired') }}
          </template>
          <template v-else>
            <fa :icon="['fal', 'caravan-simple']" />
            {{ $t('rv.preferences.towingExperienceRequired') }}
          </template>
        </div>
      </ZGridItem>

      <ZGridItem
        v-if="eventsParticipation"
        lg="6"
      >
        <div class="rule">
          <fa :icon="['fal', 'ban']" />
          {{ $t('rv.preferences.noFestivalsOrEvents') }}
        </div>
      </ZGridItem>

      <ZGridItem
        v-if="!rvAllowCrossBorderTravel"
        lg="6"
      >
        <div class="rule">
          <fa :icon="['fal', 'ban']" />
          {{ $t('rv.preferences.noCrossBorderTravel') }}
        </div>
      </ZGridItem>

      <ZGridItem
        v-if="!rvAllowPets"
        lg="6"
      >
        <div class="rule">
          <IconNoPets />
          {{ $t('rv.preferences.noPetsAllowed') }}
        </div>
      </ZGridItem>
    </ZGrid>
  </section>
</template>

<script setup lang="ts">
import { FLEXIBLE_CHECK_IN_OUT } from '~/constants'

const {
  rvAllowCrossBorderTravel,
  rvAllowOnlyExperiencedSimilarRV,
  rvAllowFestivalEventsParticipation,
  rvAllowPets,
  rvCheckInStartTime,
  rvCheckInEndTime,
  rvCheckoutStartTime,
  rvCheckoutEndTime,
  rvIsMotorized,
} = useRvDetails()

const { t } = useI18n({
  useScope: 'local',
})

const eventsParticipation = computed(() => rvAllowFestivalEventsParticipation.value === 'No')

const checkInTime = computed(() => {
  const Start = rvCheckInStartTime.value
  const End = rvCheckInEndTime.value

  if ((!Start && !End) || (Start === FLEXIBLE_CHECK_IN_OUT && End === FLEXIBLE_CHECK_IN_OUT)) {
    return t('FlexiblePickupTime')
  }
  else if ((Start !== null && End !== null) && Start > FLEXIBLE_CHECK_IN_OUT && End > FLEXIBLE_CHECK_IN_OUT) {
    return t('PickupFrom', {
      Start: translateTime(Start),
      End: translateTime(End),
    })
  }
  else if ((Start !== null && End !== null) && Start > FLEXIBLE_CHECK_IN_OUT) {
    return t('PickupAfter', { Time: translateTime(Start) })
  }
  else {
    return t('PickupBefore', { Time: translateTime(End || 0) })
  }
})

const checkOutTime = computed(() => {
  const Start = rvCheckoutStartTime.value
  const End = rvCheckoutEndTime.value

  if ((!Start && !End) || (Start === FLEXIBLE_CHECK_IN_OUT && End === FLEXIBLE_CHECK_IN_OUT)) {
    return t('FlexibleDropoffTime')
  }
  else if ((Start !== null && End !== null) && Start > FLEXIBLE_CHECK_IN_OUT && End > FLEXIBLE_CHECK_IN_OUT) {
    return t('DropoffFrom', {
      Start: translateTime(Start),
      End: translateTime(End),
    })
  }
  else if ((Start !== null && End !== null) && Start > FLEXIBLE_CHECK_IN_OUT) {
    return t('DropoffAfter', { Time: translateTime(Start) })
  }
  else {
    return t('DropoffBefore', { Time: translateTime(End || 0) })
  }
})
</script>

<style lang="scss" scoped>
.checkin-checkout {
  display: flex;
  gap: 0.5rem;
  @include semi-bold;
  color: getColor('primary-500');
  font-size: 1rem;
  flex-wrap: wrap;
  padding-bottom: 0.5rem;
}
.rule {
  display: flex;
  gap: 1rem;

  svg {
    width: auto !important;
    height: 1.625rem;
    color: getColor('primary-500');
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "rules": "RV rules",
    "PickupFrom": "Pickup from {Start} to {End}",
    "DropoffFrom": "Drop-off from {Start} to {End}",
    "FlexiblePickupTime": "Flexible pickup time",
    "FlexibleDropoffTime": "Flexible drop-off time",
    "PickupBefore": "Pickup before {Time}",
    "PickupAfter": "Pickup after {Time}",
    "DropoffAfter": "Drop-off after {Time}",
    "DropoffBefore": "Drop-off before {Time}"
  },
  "fr": {
    "rules": "Règles relatives au VR",
    "PickupFrom": "Collecte du VR de {Start} à {End}",
    "DropoffFrom": "Retour du VR de {Start} à {End}",
    "FlexiblePickupTime": "Heure de collecte du VR flexible",
    "FlexibleDropoffTime": "Heure de retour du VR flexible",
    "PickupBefore": "Collecte du VR avant {Time}",
    "PickupAfter": "Collecte du VR après {Time}",
    "DropoffAfter": "Retour du VR après {Time}",
    "DropoffBefore": "Retour du VR avant {Time}"
  }
}
</i18n>
