import type { Nullable, RvDetails } from '~/types'
import { COUNTRY_SHORTS, UNIT_MEASUREMENT, COUNTRY_CURRENCIES, COUNTRY_IDS, DELIVERY_ADDON_PREFIX } from '~/constants'
import { RentalTypeEnumKey, Manufacturer } from '~/lib/enums/index.js'

export const getRvDetails = (rv?: Nullable<RvDetails>) => {
  /**
   * Basic RV properties.
   * Always in the format of `rv[PropertyName]`.
   * Defaults are dependent on the schema and added manually.
   */
  const rvAliasName = toRef(() => rv?.AliasName ?? null)
  const rvAllowCrossBorderTravel = toRef(() => rv?.AllowCrossBorderTravel ?? false)
  const rvAllowFestivalEventsParticipation = toRef(() => rv?.AllowFestivalEventsParticipation ?? null)
  const rvAllowOnlyExperiencedSimilarRV = toRef(() => rv?.AllowOnlyExperiencedSimilarRV ?? false)
  const rvBedBunkDouble = toRef(() => rv?.BedBunkDouble ?? null)
  const rvBedBunkTwin = toRef(() => rv?.BedBunkTwin ?? null)
  const rvBedDinette = toRef(() => rv?.BedDinette ?? null)
  const rvBedKing = toRef(() => rv?.BedKing ?? null)
  const rvBedQueen = toRef(() => rv?.BedQueen ?? null)
  const rvBedSofa = toRef(() => rv?.BedSofa ?? null)
  const rvBookingSeasonEnd = toRef(() => rv?.BookingSeasonEnd ?? null)
  const rvBookingSeasonStart = toRef(() => rv?.BookingSeasonStart ?? null)
  const rvCalendars = toRef(() => rv?.Calendars ?? null)
  const rvChargePerHourOver = toRef(() => rv?.ChargePerHourOver ?? null)
  const rvCheckInAvailabilityDays = toRef(() => rv?.CheckInAvailabilityDays ?? null)
  const rvCheckInEndTime = toRef(() => rv?.CheckInEndTime ?? null)
  const rvCheckInStartTime = toRef(() => rv?.CheckInStartTime ?? null)
  const rvCheckoutEndTime = toRef(() => rv?.CheckoutEndTime ?? null)
  const rvCheckoutStartTime = toRef(() => rv?.CheckoutStartTime ?? null)
  const rvCleaningFee = toRef(() => rv?.CleaningFee ?? 0)
  const rvDailyKMAllowed = toRef(() => rv?.DailyKMAllowed ?? null)
  const rvDefaultPrice = toRef(() => rv?.DefaultPrice ?? 0)
  const rvDeliveryCentsPerDistanceUnit = toRef(() => rv?.DeliveryCentsPerDistanceUnit ?? null)
  const rvDeliveryInstructions = toRef(() => rv?.DeliveryInstructions ?? null)
  const rvDeliveryMaxDistance = toRef(() => rv?.DeliveryMaxDistance ?? null)
  const rvDeliveryMinimumCostCents = toRef(() => rv?.DeliveryMinimumCostCents ?? null)
  const rvFreeHoursPerDay = toRef(() => rv?.FreeHoursPerDay ?? null)
  const rvFuelType = toRef(() => rv?.FuelType ?? null)
  const rvGuests = toRef(() => rv?.Guests ?? 0)
  const rvHitchSize = toRef(() => rv?.HitchSize ?? null)
  const rvHitchWeight = toRef(() => rv?.HitchWeight ?? null)
  const rvId = toRef(() => rv?.Id ?? '')
  const rvInsideLeadDays = toRef(() => rv?.InsideLeadDays ?? 0)
  const rvInteriorFeatures = toRef(() => rv?.InteriorFeatures ?? null)
  const rvIsChargeMileage = toRef(() => rv?.IsChargeMileage ?? null)
  const rvIsDeliveryOnly = toRef(() => rv?.IsDeliveryOnly ?? false)
  const rvIsFeatured = toRef(() => rv?.IsFeatured ?? false)
  const rvIsGenerator = toRef(() => rv?.IsGenerator ?? null)
  const rvIsInstantBook = toRef(() => rv?.IsInstantBook ?? false)
  const rvIsInsured = toRef(() => rv?.IsInsured ?? false)
  const rvIsSuperHostActive = toRef(() => rv?.IsSuperHostActive ?? false)
  const rvLength = toRef(() => rv?.Length ?? null)
  const rvListAddOns = toRef(() => rv?.ListAddOns ?? null)
  const rvLocation = toRef(() => rv?.Location ?? null)
  const rvMake = toRef(() => rv?.Make ?? null)
  const rvMinimumTripLength = toRef(() => rv?.MinimumTripLength ?? 0)
  const rvModel = toRef(() => rv?.Model ?? null)
  const rvMonthlyDiscount = toRef(() => rv?.MonthlyDiscount ?? 0)
  const rvOriginalPrice = toRef(() => rv?.OriginalPrice ?? 0)
  const rvOutsideLeadDays = toRef(() => rv?.OutsideLeadDays ?? 0)
  const rvOwnerAbout = toRef(() => rv?.OwnerAbout ?? null)
  const rvOwnerAvatar = toRef(() => rv?.OwnerAvatar ?? null)
  const rvOwnerCreatedAt = toRef(() => rv?.OwnerCreatedAt ?? null)
  const rvOwnerFirstName = toRef(() => rv?.OwnerFirstName ?? null)
  const rvOwnerId = toRef(() => rv?.OwnerId ?? 0)
  const rvOwnerLastName = toRef(() => rv?.OwnerLastName ?? null)
  const rvOwnerScore = toRef(() => rv?.OwnerScore ?? null)
  const rvOwnerVerified = toRef(() => rv?.OwnerVerified ?? false)
  const rvParkAmenities = toRef(() => rv?.ParkAmenities ?? null)
  const rvPhotos = toRef(() => rv?.Photos ?? null)
  const rvRentalType = toRef(() => rv?.RentalType ?? null)
  const rvRenterCategoryRatings = toRef(() => rv?.RenterCategoryRatings ?? null)
  const rvRenterRating = toRef(() => rv?.RenterRating ?? 0)
  const rvReviews = toRef(() => rv?.Reviews ?? null)
  const rvRVBrandOther = toRef(() => rv?.RVBrandOther ?? null)
  const rvRVDescription = toRef(() => rv?.RVDescription ?? null)
  const rvRVName = toRef(() => rv?.RVName ?? null)
  const rvRVNumber = toRef(() => rv?.RVNumber ?? 0)
  const rvRVSellingDate = toRef(() => rv?.RVSellingDate ?? null)
  const rvRVType = toRef(() => rv?.RVType ?? null)
  const rvSeatbeltThreePoint = toRef(() => rv?.SeatbeltThreePoint ?? null)
  const rvSeatbeltTwoPoint = toRef(() => rv?.SeatbeltTwoPoint ?? null)
  const rvSecurityDeposit = toRef(() => rv?.SecurityDeposit ?? null)
  const rvSlideouts = toRef(() => rv?.Slideouts ?? null)
  const rvSurchargePerExtraKM = toRef(() => rv?.SurchargePerExtraKM ?? null)
  const rvTimezone = toRef(() => rv?.Timezone ?? null)
  const rvTour3DUrl = toRef(() => rv?.Tour3DUrl ?? null)
  const rvWeeklyDiscount = toRef(() => rv?.WeeklyDiscount ?? 0)
  const rvWeight = toRef(() => rv?.Weight ?? null)
  const rvYear = toRef(() => rv?.Year ?? 0)

  /**
   * Advanced `has` checks that depend on more than just the base properties.
   * Always in the format of `has[CustomCheckName]`.
   */
  const rvHasAccommodations = toRef(() => (rvBedBunkDouble.value ?? 0) + (rvBedBunkTwin.value ?? 0) + (rvBedDinette.value ?? 0) + (rvBedKing.value ?? 0) + (rvBedQueen.value ?? 0) + (rvBedSofa.value ?? 0) > 0)
  const rvHasAddons = toRef(() => rvListAddOns.value?.some((addon) => !addon?.Slug?.startsWith(DELIVERY_ADDON_PREFIX)) ?? false)
  const rvHasPerUnitDelivery = toRef(() => Boolean(rvDeliveryMaxDistance.value && rvDeliveryCentsPerDistanceUnit.value && rvDeliveryMinimumCostCents.value))

  /**
   * Helper `has` functions.
   */
  const rvHasAmenity = (amenity: string) => rvInteriorFeatures.value && Boolean(rvInteriorFeatures.value.find((item) => String(item).toLowerCase() === String(amenity).toLowerCase()))

  /**
   * Basic `is` checks.
   * Always in the format of `is[PropertyName]`.
   */
  const rvIsFromUs = toRef(() => rvLocation.value?.Country === COUNTRY_SHORTS.US) // TODO: replace constant with backend type.
  const rvIsFromCanada = toRef(() => rvLocation.value?.Country === COUNTRY_SHORTS.CA) // TODO: replace constant with backend type.
  const rvIsMotorized = toRef(() => rvRentalType.value === RentalTypeEnumKey.MotorHome) // TODO: replace enum with backend type.
  const rvIsTowable = toRef(() => rvRentalType.value === RentalTypeEnumKey.TravelTrailer) // TODO: replace enum with backend type.
  const rvIsStatic = toRef(() => rvRentalType.value === RentalTypeEnumKey.RVCottage) // TODO: replace enum with backend type.
  const rvIsChargeGeneratorEnabled = toRef(() => rvFreeHoursPerDay.value !== null && rvChargePerHourOver.value !== null)
  const rvIsChargeMileageEnabled = toRef(() => rvDailyKMAllowed.value !== null && rvSurchargePerExtraKM.value !== null && rvDailyKMAllowed.value >= 0 && rvSurchargePerExtraKM.value !== 0)

  /**
   * Advanced properties that depend on more than just the base properties.
   * Always in the format of `rv[CustomPropertyName]`.
   */
  const rvFormattedName = toRef(() => rvRVName.value ? rvRVName.value.replace(/"/g, '\\"') : '')
  const rvFormattedDescription = toRef(() => rvRVDescription.value ? rvRVDescription.value.replace(/"/g, '\\"') : '')
  const rvFullModelLabel = toRef(() => rvYear.value && rvModel.value ? `${rvYear.value} ${rvMake.value === Manufacturer.OTHER ? rvRVBrandOther.value : rvMake.value} ${rvModel.value}` : '') // TODO: replace constant with backend type.
  const rvComputedTimezone = toRef(() => rvTimezone.value ?? rvLocation.value?.Timezone ?? 'America/New_York')
  const rvMinBookableDate = toRef(() => hasValue(rv?.InsideLeadDays) ? dayjs().tz(rvComputedTimezone.value).startOf('day').add(rvInsideLeadDays.value, 'days').toDate() : null)
  const rvMaxBookableDate = toRef(() => hasValue(rv?.OutsideLeadDays) ? dayjs().tz(rvComputedTimezone.value).startOf('day').add(rvOutsideLeadDays.value, 'days').toDate() : null)
  const rvMeasurementUnit = toRef(() => rvIsFromCanada.value ? UNIT_MEASUREMENT.KM : UNIT_MEASUREMENT.MILES) // TODO: replace constant with backend type.
  const rvCurrency = toRef(() => (rvIsFromCanada.value ? COUNTRY_CURRENCIES.CA : COUNTRY_CURRENCIES.US)) // TODO: replace constant with backend type.
  const rvCountryId = toRef(() => rvLocation.value?.Country ? (COUNTRY_IDS[rvLocation.value.Country as keyof typeof COUNTRY_IDS] || COUNTRY_IDS.CA) : null) // TODO: replace constant with backend type.
  const rvDeliveryMaxDistanceInKm = toRef(() => rvIsFromUs.value && rvDeliveryMaxDistance.value ? miToKm(rvDeliveryMaxDistance.value) : rvDeliveryMaxDistance.value ?? 0)
  const rvAllowPets = toRef(() => Boolean(rvInteriorFeatures.value?.includes('PetFriendly')))
  const rvWeeklyPrice = toRef(() => rvDefaultPrice.value - (rvDefaultPrice.value / 100 * rvWeeklyDiscount.value))
  const rvMonthlyPrice = toRef(() => rvDefaultPrice.value - (rvDefaultPrice.value / 100 * rvMonthlyDiscount.value))
  const rvLocalizedRVType = toRef(() => rvRVType.value ? `rvType.${rvRVType.value}` : 'rvType.rvCottage')

  return {
    rvAliasName,
    rvAllowCrossBorderTravel,
    rvAllowFestivalEventsParticipation,
    rvAllowOnlyExperiencedSimilarRV,
    rvBedBunkDouble,
    rvBedBunkTwin,
    rvBedDinette,
    rvBedKing,
    rvBedQueen,
    rvBedSofa,
    rvBookingSeasonEnd,
    rvBookingSeasonStart,
    rvCalendars,
    rvChargePerHourOver,
    rvCheckInAvailabilityDays,
    rvCheckInEndTime,
    rvCheckInStartTime,
    rvCheckoutEndTime,
    rvCheckoutStartTime,
    rvCleaningFee,
    rvDailyKMAllowed,
    rvDefaultPrice,
    rvDeliveryCentsPerDistanceUnit,
    rvDeliveryInstructions,
    rvDeliveryMaxDistance,
    rvDeliveryMinimumCostCents,
    rvFreeHoursPerDay,
    rvFuelType,
    rvGuests,
    rvHitchSize,
    rvHitchWeight,
    rvId,
    rvInsideLeadDays,
    rvInteriorFeatures,
    rvIsChargeMileage,
    rvIsDeliveryOnly,
    rvIsFeatured,
    rvIsGenerator,
    rvIsInstantBook,
    rvIsInsured,
    rvIsSuperHostActive,
    rvLength,
    rvListAddOns,
    rvLocation,
    rvMake,
    rvMinimumTripLength,
    rvModel,
    rvMonthlyDiscount,
    rvOriginalPrice,
    rvOutsideLeadDays,
    rvOwnerAbout,
    rvOwnerAvatar,
    rvOwnerCreatedAt,
    rvOwnerFirstName,
    rvOwnerId,
    rvOwnerLastName,
    rvOwnerScore,
    rvOwnerVerified,
    rvParkAmenities,
    rvPhotos,
    rvRentalType,
    rvRenterCategoryRatings,
    rvRenterRating,
    rvReviews,
    rvRVBrandOther,
    rvRVDescription,
    rvRVName,
    rvRVNumber,
    rvRVSellingDate,
    rvRVType,
    rvSeatbeltThreePoint,
    rvSeatbeltTwoPoint,
    rvSecurityDeposit,
    rvSlideouts,
    rvSurchargePerExtraKM,
    rvTimezone,
    rvTour3DUrl,
    rvWeeklyDiscount,
    rvWeight,
    rvYear,

    rvHasAccommodations,
    rvHasAddons,
    rvHasPerUnitDelivery,

    rvHasAmenity,

    rvIsFromUs,
    rvIsFromCanada,
    rvIsMotorized,
    rvIsTowable,
    rvIsStatic,
    rvIsChargeGeneratorEnabled,
    rvIsChargeMileageEnabled,

    rvFormattedName,
    rvFormattedDescription,
    rvFullModelLabel,
    rvComputedTimezone,
    rvMinBookableDate,
    rvMaxBookableDate,
    rvMeasurementUnit,
    rvCurrency,
    rvCountryId,
    rvDeliveryMaxDistanceInKm,
    rvAllowPets,
    rvWeeklyPrice,
    rvMonthlyPrice,
    rvLocalizedRVType,
  }
}
