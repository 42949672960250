<template>
  <div
    id="similar-rvs"
    class="similar"
  >
    <ZContainer>
      <h3>{{ t('similarRvs') }}</h3>
      <ContentSimilarRvs
        :rv-id="rvId"
        :location="computedLocation"
      />
    </ZContainer>
  </div>
</template>

<script setup lang="ts">
const {
  rvId,
  rvLocation,
} = useRvDetails()

const { t } = useI18n()

const computedLocation = computed(() => {
  return {
    City: rvLocation.value?.City ?? '',
    Country: rvLocation.value?.Country ?? '',
    State: rvLocation.value?.State ?? '',
  }
})
</script>

<style lang="scss" scoped>
.similar {
  background-color: getColor('primary-25');
  padding: 5rem 0;

  h3 {
    @include subtitle-1;
  }

  :deep(.grid-list) {
    @include media-min-size(medium) {
      grid-template-columns: repeat(3, 1fr) !important;
    }

    .zcard {
      border-radius: 1rem;
      box-shadow: 0.25rem 0.25rem 1.5rem rgba(3, 46, 90, 0.04);

      :deep(.zcard-body a) {
        padding: 1rem;
      }
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "similarRvs": "Similar RVs"
  },
  "fr": {
    "similarRvs": "Jeter un coup d’oeil à ces VR semblables"
  }
}
</i18n>
