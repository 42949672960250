<template>
  <ZGrid>
    <ZGridItem lg="8">
      <h1>{{ rvRVName }}</h1>
      <div class="mt-2 mt-lg-1 details">
        <div class="details__meta">
          <NuxtLink
            v-if="rvReviews?.length"
            href="#similar-rvs"
          >
            <UserStatRatingReviews
              :amount="rvReviews.length"
              :rating="roundToNearestDecimal(rvRenterRating)"
              class="mr-3"
            />
          </NuxtLink>

          <ul>
            <li>{{ t(`rvType.${rvRentalType}`) }}</li>
            <li>{{ t('rv.sleeps') }} {{ rvGuests }}</li>
            <li v-if="!rvIsStatic">
              {{ rvLength }} {{ t('unit.ft') }}
            </li>
          </ul>
        </div>

        <RvRentalDistanceCalculation
          v-if="rvLocation"
          class="details__distance"
        />
      </div>
    </ZGridItem>
  </ZGrid>
</template>

<script setup lang="ts">
const {
  rvRVName,
  rvReviews,
  rvRenterRating,
  rvRentalType,
  rvGuests,
  rvLength,
  rvLocation,
  rvIsStatic,
} = useRvDetails()

const { t } = useI18n({
  useScope: 'local',
})
</script>

<style lang="scss" scoped>
h1 {
  @include title-2;

  color: getColor('primary-500');
  margin: 0;
  padding: 0;
  @include media-max-size(large) {
    @include subtitle-1;
  }
}

.details {
  @include media-max-size(xLarge) {
    @include caption;
  }

  ul {
    color: getColor('primary-350');
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    li::after {
      content: '・';
    }

    li:last-child::after {
      content: none;
    }

    @include media-min-size(large) {
      margin-right: 1rem;
    }
  }

  :deep(.rating-reviews) {
    white-space: nowrap;
    cursor: pointer;

    .review-count {
      text-decoration: underline;
    }

    @include media-max-size(medium) {
      .review-label {
        display: none;
      }
    }
  }
}

.details__meta {
  display: flex;
}
</style>

<i18n src="~/locales/common/units.json" lang="json" />
