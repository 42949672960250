<template>
  <div class="distance">
    {{ location }} <span>{{ fromDistance }}</span>
  </div>
</template>

<script setup lang="ts">
const { rvLocation, rvMeasurementUnit } = useRvDetails()

const { t } = useI18n({
  useScope: 'local',
})

const { $search } = useNuxtApp()
const searchCity = computed(() => $search.parameters.location?.city?.long_name || '')
const location = computed(() =>
  rvLocation.value?.City && rvLocation.value?.State
    ? `${rvLocation.value.City}, ${lookupStateToIsoCode(rvLocation.value.State)}`
    : '',
)
const fromDistance = computed(() =>
  distance.value && $search.parameters.location?.city ? `(${distance.value} ${t('from')} ${searchCity.value})` : '',
)

const distance = computed(() => {
  if (!rvLocation.value?.Latitude || !rvLocation.value?.Longitude) {
    return formatDistance(0, rvMeasurementUnit.value)
  }

  const userLocation = {
    lat: Number($search.parameters.location?.center?.lat),
    lng: Number($search.parameters.location?.center?.lng),
  }

  const location = { lat: rvLocation.value?.Latitude, lng: rvLocation.value?.Longitude }

  const d = distanceBetweenTwoPoints(userLocation, location)

  return formatDistance(d, rvMeasurementUnit.value)
})
</script>

<style lang="scss" scoped>
.distance {
  @include semi-bold;

  color: getColor('primary-500');
  @include media-max-size(large) {
    @include caption-strong;

    color: getColor('primary-350');
  }
  span {
    @include regular-weight;

    color: getColor('primary-350');
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "from": "from"
  },
  "fr": {
    "from": "de"
  }
}
</i18n>
