<template>
  <ul
    class="social-share"
    :class="{ 'items-vertical': showItemsVertical }"
  >
    <li>
      <ZButton
        icon
        size="lg"
        class="share-btn facebook"
        :href="linkFacebook"
        target="_blank"
        @click="trackShare('facebook')"
      >
        <fa :icon="['fab', 'facebook']" />
      </ZButton>
    </li>
    <li>
      <ZButton
        icon
        size="lg"
        class="share-btn twitter"
        :href="linkTwitter"
        target="_blank"
        @click="trackShare('twitter')"
      >
        <fa :icon="['fab', 'x-twitter']" />
      </ZButton>
    </li>
    <li>
      <ZButton
        icon
        size="lg"
        class="share-btn email"
        :href="linkEmail"
        target="_blank"
        @click="trackShare('email')"
      >
        <fa :icon="['fas', 'envelope']" />
      </ZButton>
    </li>
    <li>
      <ZButton
        icon
        size="lg"
        class="share-btn messenger"
        :href="linkMessenger"
        target="_blank"
        @click="trackShare('messenger')"
      >
        <fa :icon="['fab', 'facebook-messenger']" />
      </ZButton>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { trackShareButtonClicked } from '~/lib/tracking'

const props = withDefaults(defineProps<{
  url: string
  page: string
  showItemsVertical?: boolean
}>(), {
  showItemsVertical: false,
})

const runtimeConfig = useRuntimeConfig()
const linkFacebook = computed(() => `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl('fb')}`)
const linkTwitter = computed(() => `https://twitter.com/intent/tweet?url=${encodedUrl('tw')}`)
const linkEmail = computed(() => `mailto:?body=${encodedUrl('email')}`)
const linkMessenger = computed(
  () =>
    `http://www.facebook.com/dialog/send?app_id=${runtimeConfig.public.facebook.appId}&link=${encodedUrl('fbms')}&redirect_uri=https://www.rvezy.com/`,
)

const encodedUrl = (source: string) => {
  const separator = props.url.includes('?') ? '&' : '?'
  return encodeURI(props.url + `${separator}utm_source=sb${source}`)
}

const trackShare = (type: string) => {
  trackShareButtonClicked({
    url: props.url,
    type,
    page: props.page,
  })
}
</script>

<style lang="scss" scoped>
.items-vertical {
  flex-direction: column;
  height: 13rem;
  margin-left: 1rem;
}
.social-share {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;

  li:not(:last-child) {
    margin-right: 1.25rem;
  }

  .share-btn {
    color: #fff;
    &.facebook {
      background: #4f5a8d;
    }

    &.twitter {
      background: #000;
    }

    &.email {
      background: getColor('primary-300');
    }

    &.messenger {
      background: #754eff;
    }
  }
}
</style>
