import type { Nullable, RvDetails } from '~/types'

/**
 * Returns the RV object from the state with a bunch of helpers/checks.
 */
export default function useRvDetails(newRvDetails?: Ref<Nullable<RvDetails>>) {
  const rv = useSelectedRvDetails()

  // Set the RV data if it's provided (even when it's null), otherwise use
  // what's in the state.
  if (newRvDetails !== undefined) {
    rv.value = newRvDetails.value
  }

  return getRvDetails(rv.value)
}
